/* Код для подключения шрифта в /css/stylename.css */
/* font-family: "ClearSansRegular"; */
@font-face {
  font-family: "ClearSansRegular";
  src: url("../fonts/ClearSansRegular/ClearSansRegular.eot");
  src: url("../fonts/ClearSansRegular/ClearSansRegular.eot?#iefix")format("embedded-opentype"),
  url("../fonts/ClearSansRegular/ClearSansRegular.woff") format("woff"),
  url("../fonts/ClearSansRegular/ClearSansRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "ClearSansBold"; */
@font-face {
  font-family: "ClearSansBold";
  src: url("../fonts/ClearSansBold/ClearSansBold.eot");
  src: url("../fonts/ClearSansBold/ClearSansBold.eot?#iefix")format("embedded-opentype"),
  url("../fonts/ClearSansBold/ClearSansBold.woff") format("woff"),
  url("../fonts/ClearSansBold/ClearSansBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "ClearSansItalic"; */
@font-face {
  font-family: "ClearSansItalic";
  src: url("../fonts/ClearSansItalic/ClearSansItalic.eot");
  src: url("../fonts/ClearSansItalic/ClearSansItalic.eot?#iefix")format("embedded-opentype"),
  url("../fonts/ClearSansItalic/ClearSansItalic.woff") format("woff"),
  url("../fonts/ClearSansItalic/ClearSansItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "ClearSansLight"; */
@font-face {
  font-family: "ClearSansLight";
  src: url("../fonts/ClearSansLight/ClearSansLight.eot");
  src: url("../fonts/ClearSansLight/ClearSansLight.eot?#iefix")format("embedded-opentype"),
  url("../fonts/ClearSansLight/ClearSansLight.woff") format("woff"),
  url("../fonts/ClearSansLight/ClearSansLight.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "ClearSansMedium"; */
@font-face {
  font-family: "ClearSansMedium";
  src: url("../fonts/ClearSansMedium/ClearSansMedium.eot");
  src: url("../fonts/ClearSansMedium/ClearSansMedium.eot?#iefix")format("embedded-opentype"),
  url("../fonts/ClearSansMedium/ClearSansMedium.woff") format("woff"),
  url("../fonts/ClearSansMedium/ClearSansMedium.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "ClearSansBoldItalic"; */
@font-face {
  font-family: "ClearSansBoldItalic";
  src: url("../fonts/ClearSansBoldItalic/ClearSansBoldItalic.eot");
  src: url("../fonts/ClearSansBoldItalic/ClearSansBoldItalic.eot?#iefix")format("embedded-opentype"),
  url("../fonts/ClearSansBoldItalic/ClearSansBoldItalic.woff") format("woff"),
  url("../fonts/ClearSansBoldItalic/ClearSansBoldItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
/* font-family: "ClearSansMediumItalic"; */
@font-face {
  font-family: "ClearSansMediumItalic";
  src: url("../fonts/ClearSansMediumItalic/ClearSansMediumItalic.eot");
  src: url("../fonts/ClearSansMediumItalic/ClearSansMediumItalic.eot?#iefix")format("embedded-opentype"),
  url("../fonts/ClearSansMediumItalic/ClearSansMediumItalic.woff") format("woff"),
  url("../fonts/ClearSansMediumItalic/ClearSansMediumItalic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "UniversLTCYRBoldCond";
  src: url("../fonts/UniversLTCYR-67BoldCond/UniversLTCYR-67BoldCond.eot");
  src: url("../fonts/UniversLTCYR-67BoldCond/UniversLTCYR-67BoldCond.eot?#iefix")format("embedded-opentype"),
  url("../fonts/UniversLTCYR-67BoldCond/UniversLTCYR-67BoldCond.woff") format("woff"),
  url("../fonts/UniversLTCYR-67BoldCond/UniversLTCYR-67BoldCond.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "UniversLTCYRBold";
  src: url("../fonts/UniversLTCYR-65Bold/UniversLTCYR-65Bold.otf");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "UniversLTCYRRoman";
  src: url("../fonts/UniversLTCYR-55Roman/UniversLTCYR-55Roman.eot");
  src: url("../fonts/UniversLTCYR-55Roman/UniversLTCYR-55Roman.eot?#iefix")format("embedded-opentype"),
  url("../fonts/UniversLTCYR-55Roman/UniversLTCYR-55Roman.woff") format("woff"),
  url("../fonts/UniversLTCYR-55Roman/UniversLTCYR-55Roman.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "UniversLTCYRLight";
  src: url("../fonts/UniversLTCYR-45Light/UniversLTCYR-45Light.eot");
  src: url("../fonts/UniversLTCYR-45Light/UniversLTCYR-45Light.eot?#iefix")format("embedded-opentype"),
  url("../fonts/UniversLTCYR-45Light/UniversLTCYR-45Light.woff") format("woff"),
  url("../fonts/UniversLTCYR-45Light/UniversLTCYR-45Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "HelveticaNeueCyrRoman";
  src: url("../fonts/HelveticaNeueCyrRoman/HelveticaNeueCyrRoman.eot");
  src: url("../fonts/HelveticaNeueCyrRoman/HelveticaNeueCyrRoman.eot?#iefix")format("embedded-opentype"),
  url("../fonts/HelveticaNeueCyrRoman/HelveticaNeueCyrRoman.woff") format("woff"),
  url("../fonts/HelveticaNeueCyrRoman/HelveticaNeueCyrRoman.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
