$global-font-icon: 'colibri-icons';
@font-face {
  font-family: $global-font-icon;
  src:url("../fonts/colibri-icons.eot");
  src:url("../fonts/colibri-icons.eot?#iefix") format("embedded-opentype"),
    url("../fonts/colibri-icons.woff") format("woff"),
    url("../fonts/colibri-icons.ttf") format("truetype"),
    url("../fonts/colibri-icons.svg#svgcolibri-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}


$icon-ATM: "\EA01" !default;

$icon-arrow-double-right: "\EA02" !default;

$icon-arrow-left: "\EA03" !default;

$icon-arrow-right: "\EA04" !default;

$icon-parking: "\EA05" !default;

$icon-parking_2: "\EA06" !default;

$icon-search: "\EA07" !default;

$icon-socials-facebook: "\EA08" !default;

$icon-socials-flickr: "\EA09" !default;

$icon-socials-google-plus: "\EA0A" !default;

$icon-socials-instagram: "\EA0B" !default;

$icon-socials-linkedin: "\EA0C" !default;

$icon-socials-pinterest: "\EA0D" !default;

$icon-socials-twitter: "\EA0E" !default;

$icon-socials-vimeo: "\EA0F" !default;

$icon-socials-vk: "\EA10" !default;

$icon-socials-web: "\EA11" !default;

$icon-socials-youtube: "\EA12" !default;

$icon-time: "\EA13" !default;


.icon {
 &:before {
   font-family: $global-font-icon
 }
}


.icon-ATM{
    &:before{
      content: $icon-ATM;
    }
}

.icon-arrow-double-right{
    &:before{
      content: $icon-arrow-double-right;
    }
}

.icon-arrow-left{
    &:before{
      content: $icon-arrow-left;
    }
}

.icon-arrow-right{
    &:before{
      content: $icon-arrow-right;
    }
}

.icon-parking{
    &:before{
      content: $icon-parking;
    }
}

.icon-parking_2{
    &:before{
      content: $icon-parking_2;
    }
}

.icon-search{
    &:before{
      content: $icon-search;
    }
}

.icon-socials-facebook{
    &:before{
      content: $icon-socials-facebook;
    }
}

.icon-socials-flickr{
    &:before{
      content: $icon-socials-flickr;
    }
}

.icon-socials-google-plus{
    &:before{
      content: $icon-socials-google-plus;
    }
}

.icon-socials-instagram{
    &:before{
      content: $icon-socials-instagram;
    }
}

.icon-socials-linkedin{
    &:before{
      content: $icon-socials-linkedin;
    }
}

.icon-socials-pinterest{
    &:before{
      content: $icon-socials-pinterest;
    }
}

.icon-socials-twitter{
    &:before{
      content: $icon-socials-twitter;
    }
}

.icon-socials-vimeo{
    &:before{
      content: $icon-socials-vimeo;
    }
}

.icon-socials-vk{
    &:before{
      content: $icon-socials-vk;
    }
}

.icon-socials-web{
    &:before{
      content: $icon-socials-web;
    }
}

.icon-socials-youtube{
    &:before{
      content: $icon-socials-youtube;
    }
}

.icon-time{
    &:before{
      content: $icon-time;
    }
}



