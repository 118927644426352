footer{
  height: 52px;
  line-height: 52px;
  padding: 0;
}

.footer-container {
  padding: 0;
  position: relative;
}

.lang-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 56px;
  padding-right: 0;

  a {
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-right: 10px;
    background-color: #e1e1e1;
    color: #898989;
    text-align: center;
    line-height: 30px;
    text-transform: uppercase;
    border-radius: 50%;
    cursor: pointer;


    &.active {
      background-color: #67c5d1;
      color: white;
    }
  }
}

.mobile{
  display: flex;
  align-items: center;
  padding-left: 0;

  &.footer-icons {
    text-align: left;
  }

  .tel {
    color: black;
    font-size: 16px;
    font-weight: normal !important;
    margin-left: 10px;
  }
}
.footer-nav{
  position: relative;

  .work-time {
    width: 585px;
    position: absolute;
    top: -185px;
    left: calc(50% - 293px);
    display: none;

    .time-line {
      display: none;
    }

    &.vis-time {
      display: block;
    }

    .close-work-time {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      opacity: 1;

      span {
        width: 100%;
        height: 2px;
        display: block;
        border-radius: 3px;
        cursor: pointer;

        &:first-child {
          background-color: #f47a8e;
          transform: rotate(45deg) translate(10px, 10px);
        }

        &:last-child {
          background-color: #6ecad4;
          transform: rotate(-45deg) translate(-8.5px, 9px);
        }
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -19px;
      right: 55px;
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 20px solid #d3d1d1;
    }

    .top {
      background-color: #5f6e7d;
      height: 55px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      h2 {
        font-size: 18px;
        color: white;
        text-transform: uppercase;
        font-weight: normal;
        margin: 0;
      }
      img {
        margin-right: 20px;
      }
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #d3d1d1;
      padding: 20px 22px;

      .desc {
        width: 33.333%;
        line-height: 20px;
        margin: 0;
        padding-right: 20px;
        font-size: 15px;
      }
      ul {
        width: 66.666%;
        display: flex;
        justify-content: space-between;
        list-style-type: circle;
        padding-left: 40px;
        border-left: 1px solid #7f7d7d;
        margin: 0;

        li {
          font-size: 15px;
          display: flex;
          flex-direction: column;
          line-height: 20px;
          align-items: flex-start;

          &.name {
            span {
              font-size: 15px;
              display: block;
              position: relative;

              &:after {
                content: '';
                width: 5px;
                height: 5px;
                left: -20px;
                top: 8px;
                display: block;
                position: absolute;
                background-color: black;
                border-radius: 50%;
              }
            }
          }

          span {

          }
        }
      }
    }
  }

  ul{
    text-align: center;
    padding: 0;
  }
  li{
    display: inline-block;
    list-style: none;
    font-size: 15px;


    a{
      padding: 0 20px;
      color: black;
      cursor: pointer;
      border-right: 1px solid black;

      &.vis-time {
        font-family: "ClearSansBold", sans-serif;
        text-decoration: underline;
      }
    }
  }
  li:last-child{
    a{
      border: 0;
    }
  }
}
.footer-line{
  margin: 0;
  border: none;
  position: absolute;
  width: 100%;
  height: 1px;
  background: #d6d6d6;
  left: 0;
}
.footer-icons{
  text-align: right;
  .icon{
    cursor: pointer;
    color: #a6a6a6;
    font-size: 32px;
    text-decoration: none;
    &:hover{
      text-decoration: none;
      color: #939393;
    }
  }
}
@media (max-width: 1199px) {
  .footer-nav{
    .work-time {
      left: calc(50% - 285px);

      &:after {
        right: 100px;
      }
    }

    li{
      a {
        padding: 0 10px;
      }
    }
  }
}
@media (max-width: 991px) {
  .mobile .tel {
    color: black;
    font-size: 14px;
  }

  .footer-nav {
    .work-time {
      left: calc(50% - 253px);
      width: 550px;

      &:after {
        right: 145px;
      }
    }
  }

  .footer-nav li {
    list-style: none;
    font-size: 12px;


    a{
      padding: 0 5px;
    }
  }

}
@media (max-width: 768px) {
  footer {
    float: left;
    width: 100%;
    height: 100%;
  }


  .mobile{
    float: none;
    position: static;
    text-align: right;

    .tel {
      display: block;
      position: absolute;
      right: 15px;
      font-size: 21px;
      bottom: 30px;
    }
  }

  .lang-icons {
    float: right;
    margin-top: 20px;

    a {
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 21px;
    }
  }

  .footer-nav{
    .work-time {
      /*width: calc(100vw - 17px);*/
      width: 100vw;
      position: absolute;
      top: -215px;
      left: -15px;

      .content {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 20px;

        .time-line {
          margin-bottom: 10px;
          display: block;
          width: 100%;
          height: 1px;
          background-color: #7f7d7d;
        }

        .desc {
          width: 260px;
          text-align: left;
          line-height: 20px;
          padding: 0;
          margin: 0 0 10px 0;
          font-size: 15px;
        }

        ul {
          border: 0;
          padding-left: 20px;
          width: 80%;
          max-width: 350px;
        }
      }

      &:after {
        content: none;
      }
    }

    ul{
      margin-top: 15px;
      padding: 0;
      text-align: left;
      li {
        line-height: 29px;
        display: inherit;
        a {
          color: black;
          cursor: pointer;
          padding: 0;
          border: none;
          font-size: 16px;
          letter-spacing: -0.1px;
        }
      }
    }
  }
  .footer-icons{
    display: inline-block;
    margin-top: 20px;
    line-height: 148px;
    padding: 0;
    .icon {
      font-size: 55px;
    }
  }
  .mobile{
    height: inherit;
    line-height: inherit;
    text-align: right;

    &.footer-icons {
      text-align: right;
    }

    a{
      font-size: 19px;
      letter-spacing: -0.5px;
    }
  }
}
