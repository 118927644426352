$pink-red: #cd2b46;
$bord-grey: #9e9e9e;

body {
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-appearance: none;
  -webkit-overflow-scrolling: touch;
}

.contacts {
  min-height: calc(100vh - 219px);
  margin-top: 15px;
  margin-bottom: 75px;

  .contact-info {
    font-family: UniversLTCYRRoman, sans-serif;
    .info-sheet {
      &:last-child {
        margin-bottom: 0;
      }
      &:first-child {
        margin-top: 15px;
      }

      font-size: 16px;
      margin-bottom: 30px;

      h2 {
        color: $pink-red;
        font-size: 18px;
        text-transform: uppercase;
        font-family: UniversLTCYRRoman, sans-serif;
        font-weight: 700;
        margin-top: 0;
      }

      form {

        .input-cover {

          position: relative;

          &.wrong-field {
            input[type="text"], textarea {
              border: 1px solid $pink-red;
            }

            &:hover {
              .field-alert {
                display: block;
              }
            }
            &.select-box {
              border: 1px solid $pink-red;
            }
          }

          .field-alert {
            &:after {
              content: '';
              display: block;
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 8px solid $pink-red;
              position: absolute;
              bottom: -7px;
              left: -webkit-calc(50% - 10px);
              left: calc(50% - 5px);
            }

            font-size: 16px;
            padding: 10px 12px;
            line-height: 16px;
            color: white;
            width: 100%;
            background-color: $pink-red;
            position: absolute;
            top: -51px;
            left: 0;
            display: none;
            z-index: 2;
          }

          input[type="text"] {
            width: 100%;
            height: 35px;
            border: 1px solid $bord-grey;
            padding: 0 12px;
            color: black;
            font-size: 16px;
            line-height: 35px;
            outline: none;
            margin-bottom: 16px;
          }
          input[type="text"]::-webkit-input-placeholder, textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: black;
            opacity: 1;
          }
          input[type="text"]::-moz-placeholder, textarea::-moz-placeholder { /* Firefox 19+ */
            color: black;
            opacity: 1;
          }
          input[type="text"]:-ms-input-placeholder, textarea:-ms-input-placeholder { /* IE 10+ */
            color: black;
            opacity: 1;
          }
          input[type="text"]:-moz-placeholder, textarea:-moz-placeholder { /* Firefox 18- */
            color: black;
            opacity: 1;
          }

        }

        .select-box {
          width: 100%;
          height: 35px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border: 1px solid $bord-grey;
          margin-bottom: 16px;
          z-index: 1;

          &:after {
            content: '';
            background-image: url(../images/triangle-pred.png);
            background-repeat: no-repeat;
            width: 10px;
            height: 8px;
            display: block;
            position: absolute;
            right: 13px;
            top: 13px;
            cursor: pointer;
          }

          .selected-span {
            padding: 0 12px;
            display: block;
            width: 100%;
            line-height: 35px;
            cursor: pointer;
            position: relative;
            z-index: 1;
          }
          .select-list {

            &::-webkit-scrollbar {
              width: 10px;
            }

            &::-webkit-scrollbar-track {
              background-color: #E9E9E9;
            }

            &::-webkit-scrollbar-thumb {
              background-color: #bebebe;
            }

            position: absolute;
            top: 33px;
            left: 0;
            margin: 0 0 0 -1px;
            display: none;
            width: calc(100% + 2px);
            border: 1px solid $bord-grey;
            background-color: white;
            height: 205px;
            overflow: auto;

            span {
              &:hover {
                color: white;
                background-color: $pink-red;
              }

              display: block;
              width: 100%;
              cursor: pointer;
              line-height: 30px;
              padding: 0 12px;
            }
          }
        }
        textarea {
          width: 100%;
          height: 120px;
          resize: none;
          padding: 5px 12px;
          margin-bottom: 10px;
          font-size: 16px;
          outline: none;
        }
        .form-bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;

          input {
            &:hover {
              background-color: #ff4a68;
            }
            width: 52%;
            height: 40px;
            border: 0;
            border-radius: 0;
            text-transform: uppercase;
            color: white;
            outline: none;
            font-size: 18px;
            line-height: 40px;
            background-color: $pink-red;
            display: block;
            text-align: center;

          }

          span {
            color: $pink-red;
            width: 45%;
            font-size: 14px;
            line-height: 18px;
            display: block;

            &.hidden-alert {
              visibility: hidden;
            }
          }
        }
      }
    }
  }

  .map-cover {
    position: relative;

    .map-link {
      &:hover {
        color: black;
        background-color: rgb(235, 235, 235);
      }

      position: absolute;
      background: white;
      top: 10px;
      height: 35px;
      line-height: 35px;
      right: 25px;
      display: block;
      border-radius: 2px;
      z-index: 1;
      padding: 0px 8px;
      box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
      cursor: pointer;
      text-decoration: none;
      color: rgb(86, 86, 86);
      font-size: 11px;
      font-weight: 700;
      letter-spacing: 0.1px;
    }

    #map {
      height: 546px;
    }
  }

}

@media screen and (max-width: 1199px) {
  .contacts {
    .contact-info {
      .info-sheet {
        font-size: 14px;

        form {

          .input-cover {
            .field-alert {
              font-size: 14px;
            }
          }

          .form-bottom {
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .map-cover {
    #map {
      height: 569px;
    }
  }
}

@media screen and (max-width: 991px) {
  .contacts {
    .contact-info {
      .info-sheet {
        font-size: 16px;
        form {

          .input-cover {
            .field-alert {
              font-size: 16px;
            }
          }

          .form-bottom {
            margin-bottom: 30px;
            span {
              font-size: 17.46px;
              line-height: 22.45px;
            }
          }
        }
      }
    }
  }
  .map-cover {
    #map {
      height: 569px;
    }
  }
}

@media screen and (max-width: 767px) {
  .contacts {
    margin-bottom: 50px;

    .contact-info {
      .info-sheet {
        margin-bottom: 35px;
        font-size: 18px;

        h2 {
          margin-bottom: 10px;
          font-size: 22px;
        }

        form {

          .input-cover {
            .field-alert {
              &:after {
              }

              font-size: 20px;
              height: 40px;
              line-height: 40px;
              top: -56px;
            }

            input[type="text"] {
              font-size: 20px;
              height: 40px;
              line-height: 40px;
            }

          }

          textarea {
            font-size: 20px;
            height: 150px;
          }

          .select-box {
            .selected-span {
              font-size: 20px;
            }
            .select-list {
              span {
                font-size: 20px;
                line-height: 35px;
              }
            }
          }

          .form-bottom {
            margin-bottom: 30px;
            span {
              font-size: 17.46px;
              line-height: 22.45px;
            }

            input {
              font-size: 22.45px;
              height: 50px;
              line-height: 55px;
            }
          }
        }
      }
    }
    .map-cover {
      padding: 0;

      .map-link {
        right: 10px;
      }

      #map {
        height: 550px;
      }
    }
  }
}