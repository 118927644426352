$black-color: #000000;
$white-color: #ffffff;
$gray-color: #636363;
$mobile-gray: #363636;

input {
  -webkit-appearance: none;
}

.navbar{
  margin: 0;
  min-height: 62px;
  border-radius: 0;
  border: none;
}
.navbar-brand{
  padding: 5px 0 0 0;
}
.navbar-header {
  float: left;
  width: 15%;
}
.navbar-collapse.collapse {
  min-height: 62px;
  padding: 0;
  padding-top: 26px;
  float: left;
  width: 85%;
}
.top-line{
  width: 100%;
  height: 1px;
  display: block;
  background: #d6d6d6;
  position: absolute;
  left: 0;
  top: 60px;
  margin: 0;
  border: none;
}
.nav {
  margin: 0;
  width: 100%;
   li > a:hover, li > a:focus {
    background-color: transparent;
  }
  .search{
    a.icon-search{
      text-align: center;
      display: block;
      width: 46px;
      padding: 9px 6px 4px 6px;
      color: black;
      &:hover{
        color: $white-color;
      }
      &:before{
        font-size: 20px;
        font-weight: 700;
      }
    }
    &:hover{
      background: $blue-grey;
      a{
        color: $white-color;
      }
    }
    &.active{
      background: $blue-grey;
      a{
        color: $white-color;
      }
    }
    .search-block{

      display: none;
      position: absolute;
      padding: 54px 57px 32px 38px;
      width: 454px;
      background: #d7d7d7;
      right: 0;
      z-index: 1000;
      &:before {
        content: "";
        width: 100%;
        height: 6px;
        display: block;
        position: absolute;
        top: -5px;
        left: 0;
        background: $blue-grey;
      }
      .bird{
        position: absolute;
        top: 13px;
        right: 13px;
      }

      &.not-enough {
        input {
          border: 1px solid #ef415e;
        }
        .n-en-message {
          display: block;
          position: absolute;
          text-align: center;
          width: 359px;
          top: 15px;
          left: calc(50% - 189px);
          line-height: 30px;
          font-size: 12px;
          color: white;
          background: #ef415e;

          &:after {
            content: '';
            position: absolute;
            top:29px;
            left: calc(50% - 5px);
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 9px solid #ef415e;
          }
        }
      }

      .n-en-message {
        display: none;
      }

      input{
        width: 320px;
        float: left;
        height: 34px;
        border: 1px solid $black-color;
        padding: 0 10px;
        outline: none;


      }
      button{
        float: left;
        width: 39px;
        height: 34px;
        color: white;
        background: $black-color;
        border: $black-color;
        font-size: 24px;
        line-height: 34px;
        position: relative;
        z-index: 1;
        outline: none;

        &:hover {
          background-color: #ef415e;
        }

        &:active {
          background-color: #31b3c2;
        }
      }
    }
    &.active{
      .search-block{
        display: block;
      }
    }
    a{
      padding: 9px 10px 9px 10px;
      &:hover{
        background: $blue-grey;
      }
    }
  }

  .main-links-list{
    &:hover, &:hover > a{
      background: $blue-grey;
      color: $white-color;
    }
    a{
      font-size: 16px;
      padding: 9px;
      letter-spacing: -1px;
      text-transform: uppercase;

    }
    .dropdown-menu {
      &:before{
        content: "";
        width: 100%;
        height: 6px;
        display: block;
        position: absolute;
        top: -5px;
        left: 0;
        background: $blue-grey;
      }
      float: left;
      list-style: none;
      padding: 13px 26px;
      padding-right: 230px;
      min-height: 230px;
      background-color: $white-color;
      border: none;
      border-radius: 0;
      box-shadow: none;
      box-shadow: -1px 3px 10px -5px rgba(0,0,0,0.75);
      .selected-menu-image{
        width: 200px;
        height: 200px;
        position: absolute;
        right: 15px;
      }
      li{
        a{
          display: inline-block;
          color: $gray-color;
          padding: 0;
          text-transform: none;
          letter-spacing: 0;
          font-size: 15px;

          &.hovered {
            color: $black-color;
            border-bottom: 1px solid $blue-grey;
            background: transparent;
            &:before {
              display: block;
              width: 13px;
              height: 13px;
              background: $blue-grey;
              position: absolute;
              content: "";
              left: -3px;
              margin-top: 9px;
            }
          }

          &:hover{
            color: $black-color;
            border-bottom: 1px solid $blue-grey;
            background: transparent;
            &:before {
              display: block;
              width: 13px;
              height: 13px;
              background: $blue-grey;
              position: absolute;
              content: "";
              left: -3px;
              margin-top: 9px;
            }
          }

        }
      }
    }
  }
  .main-links-list.to-left{
    .dropdown-menu{
      text-align: right;
      padding-right: 26px;
      padding-left: 230px;
      left: inherit;
      right: 0;
      .selected-menu-image{
        left: 15px;
        right: inherit;
      }
      li{
        a:hover:before {
          left: inherit;
          right: -3px;
        }
        a.hovered:before {
          left: inherit;
          right: -3px;
        }
      }
    }
  }
  .search{
    float: right;
  }
}
@media (max-width: 1199px) and (min-width: 768px) {
  .navbar {
    height: 111px;
    min-height: 111px;
  }
}


@media (max-width: 1199px) {

  .nav{
    /*margin-left: 13px;*/
    .main-links-list a {
      font-size: 14px;
      padding: 9px 6px 9px 6px;
      letter-spacing: -1.6px;
    }
  }
  .navbar-header {
    width: 20%;
  }
  .navbar-collapse.collapse {
    width: 100%;
  }
}



@media (min-width: 992px) {
  .nav{

    .caret{
      display: none;
    }
    .open > a,.open > a:hover,.open > a:focus {
      background-color: transparent;
      border-color: transparent;
    }
    .open > .dropdown-menu {
      display: none;
    }
  }

  ul.nav li.dropdown:hover > .dropdown-menu {
    display: block;
  }
  ul.nav li.dropdown.to-left:hover > .dropdown-menu {
    display: block;
  }
}




@media (min-width: 768px) and (max-width: 991px) {
  .navbar-header {
    float: left;
    width: 100%;
  }
  .navbar-collapse.collapse {
    min-height: 45px;
    float: left;
    padding: 0px;
    margin-top: 10px;
  }
  .nav {
    margin: 0;
    .main-links-list a {
      font-size: 13px;
      padding: 9px 5px;
      letter-spacing: -1.6px;
    }
  }

}
@media (max-width: 767px) {
  .navbar{
    margin: 0 -15px;

  }
  .navbar-header{
    padding: 0 15px;
    height: 63px;
    float: inherit;
    width: inherit;
  }
  .navbar-toggle {
    padding: 0;
    margin: 0;
    margin-top: 14px;
    width: 50px;
    height: 39px;
  }
  .navbar-collapse{
    background: $mobile-gray;
    padding: 0;
  }
  .navbar-toggle.collapsed{
    .icon-bar:nth-child(1){
      display: block;
    }
    .icon-bar:nth-child(2){
      transform: inherit;
      width: 32px;
      height: 5px;
      margin-top: 4px;
    }
    .icon-bar:nth-child(3){
      transform: inherit;
      width: 32px;
      height: 5px;
      margin-top: 4px;
    }
  }
  .icon-bar:nth-child(1){
    display: none;
    background-color: #8e99a4;
  }
  .navbar-toggle .icon-bar:nth-child(2){
    transform: rotate(45deg);
    width: 39px;
    height: 3px;
    background-color: #f47a8e;
  }
  .navbar-toggle .icon-bar:nth-child(3){
    transform: rotate(-45deg);
    width: 39px;
    height: 3px;
    margin-top: -2px;
    background-color: #6ecad4;
  }
  .navbar-toggle .icon-bar {
    margin: 0 auto;
    width: 32px;
    height: 5px;
    border-radius: 5px;
  }

  .navbar-collapse.collapse{
    border: none;
    padding-top: 0;
    background: $mobile-gray;
    width: calc(100% + 30px);
    float: none;
  }

  .nav {
      border: none;
      margin: 0;
     .open > a,
     .open > a:hover,
     .open > a:focus {
        background-color: $mobile-gray;
      }

    .search{
      float: left;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover{
        background: transparent;

      }
      a.icon-search{
        float: left;
        width: 14%;
        padding-left: 24px;
        padding-right: 20px;
        color: white;
        &:hover{
          background: transparent;
        }
      }
      .search-block{



        &:before{
          display: none;
        }
        display: flex;
        align-items: center;
        position: relative;
        padding: 4px 25px;
        padding-left: 0;
        width: 85%;
        float: left;
        background: transparent;
        .bird{
          display: none;
        }
        input {
          width: 90%;
          border-radius: 0;
          float: left;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

        }
        button {
          float: left;
          width: 10%;
          border-radius: 0;

        }
      }
    }

    .main-links-list.to-left{
      .dropdown-menu{
        padding: 0;
        text-align: left;
      }
    }

    .main-links-list.open{
      .arrow{
        transform: rotate(90deg);
      }
    }

    .main-links-list{
      border-bottom: 1px solid white;
      color: $white-color;
      float: left;
      width: 100%;
      > a.links{
        color: $white-color;
        font-size: 18px;
        padding: 6px 6px 6px 26px;
        letter-spacing: 0;
        line-height: 23px;
        .arrow{
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 0 7px 12px;
          border-color: transparent transparent transparent #ffffff;
          float: right;
          margin-top: 3px;
          margin-right: 15px;
        }
      }

      .dropdown-menu {
        width: 100%;
        padding: 0;
        min-height: inherit;
        &:before{
          display: none;
        }
        li{
          a{
            font-size: 20px;
            text-transform: uppercase;
            line-height: 19px;
            padding-top: 8px;
            padding-left: 41px;
            padding-bottom: 10px;
            letter-spacing: -0.9px;
            width: 100%;
            background: #e1e1e1;
            border-bottom: 1px solid #b2b2b2;

            &.hovered {
              color: #636363;
              border-bottom: 1px solid #b2b2b2;
              background: #e1e1e1;
              &:before {
                display: none;
              }

              &:hover {
                color: white;
                background: #ee435d;
              }
              &:active {
                color: white;
                background: #ee435d;
              }
            }

            &:hover {
              color: white;
              background: #ee435d;
            }
            &:active {
              color: white;
              background: #ee435d;
            }

            &:hover:before{
              display: none;
            }
          }
        }
      }
    }
  }

  .nav .main-links-list:hover, .nav .main-links-list:hover > a {
    background: $mobile-gray;
    color: #ffffff;
  }
  .selected-menu-image {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar {
    height: 62px;
  }
}