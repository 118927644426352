$line-color: #d6d6d6;

a {
  color: #000000;
  text-decoration: none;
  &:hover,
  &:focus{
    color: #000000;
    text-decoration: underline;
    outline: 0;
  }
}
.middle-line{
  color: $line-color;
  width: 100%;
  height: 1px;
  border: none;
  background: $line-color;
  margin: 0;

  &:last-child {
    height: 0;
  }
}

.to-top-button {
  position: fixed;
  bottom: -25px;
  left: calc(50% - 55px);
  width: 110px;
  height: 110px;
  background: white;
  border: 2px solid #5f6e7d;
  border-radius: 50%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 50;

  &.vis-to-top {
    display: flex;
  }

  span {
    font-family: ClearSansRegular, sans-serif;
    font-size: 18px;
    color: #5f6e7d;
    text-transform: uppercase;
  }
}