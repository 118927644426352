$grid-grey: #a4a4a4;
$head-grey: $blue-grey;

.top-slider {

  margin: 17px 0;

  .slide-wrap {
    height: 100%;
    overflow: hidden;
    &.loaded-carousel {
      overflow: inherit;
      .brand-column {

        &.slick-current {
          transform: translate(-1px, 0);
        }

      }
    }

    border: 1px solid $grid-grey;

    .slick-list {
      height: 100%;
      overflow: hidden;
    }

    .brand-column {
      display: flex;
      flex-direction: column;
      border-left: 1px solid $grid-grey;
      padding: 0;

      .ico-cover {

        &:last-child {
          border-bottom: 0;
        }

        height: 140px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-bottom: 1px solid $grid-grey;
        padding: 0 15px;

        img {
          max-width: 100%;
          width: 100%;
        }

      }

    }

    .slick-next, .slick-prev {
      width: 14px;
      height: 27px;
      top: calc(50% - 14px);
      background: none;
      color: transparent;
      border: 0;
      font-size: 0;
      line-height: 0;
      padding: 0;
      outline: none;
      position: absolute;

      &:before {
        content: '';
        background-image: url(../images/next.png);
        background-size: 100%;
        background-repeat: no-repeat;
        display: block;
        width: 14px;
        height: 27px;
      }
    }

    .slick-next {
      right: -29px;
    }

    .slick-prev {
      left: -29px;

      &:before {
        transform: rotate(180deg);
      }
    }

  }

}

.main-part {
  margin: 0;
  color: #111111;

  .fil-sort-side {
    margin-right: 0;
    padding-left: 0;
    padding-right: 30px;

    .sort-side, .fil-side {

      &.closed {

        h2 {

          &:after {
            transform: rotate(180deg);
          }

        }

      }

      font-family: 'UniversLTCYRRoman', 'sans-serif';

      h2 {

        &:after {
          content: '';
          background-image: url(../images/triangle.png);
          background-size: 10px 10px;
          width: 10px;
          height: 10px;
          position: absolute;
          display: none;
          top: calc(50% - 5px);
          right: 15px;
        }

        font-family: "ClearSansRegular", "sans-serif";
        font-size: 20px;
        margin: 0;
        padding: 13px 15px;
        color: white;
        background-color: $head-grey;
        text-transform: uppercase;
        cursor: default;
        position: relative;
      }

      .sort-list {

        padding: 12px 20px 20px 20px;
        list-style: none;
        border: 1px solid $grid-grey;

        li {
          &:last-child {
            margin-bottom: 0;
          }
          &:hover {
            text-decoration: underline;
          }
          &.active {
            text-decoration: none;
            font-family: 'UniversLTCYRBold', 'sans-serif';
          }
          font-size: 16px;
          cursor: pointer;
        }
      }

    }

    .sort-side {
      margin-bottom: 17px;
    }

  }

  .main-content {
    font-family: 'UniversLTCYRRoman', 'sans-serif';
    padding-right: 0;

    .az-line + .russian-alphabet {
      display: none;
    }
    .az-line {
      display: flex;
      justify-content: space-between;
      padding: 8px 20px;
      background-color: #e1e1e1;
      margin-bottom: 20px;

      a {
        &:hover {
          text-decoration: none;
        }

        &.no-active {
          text-decoration: none;
          color: #aaaaaa;
          cursor: default;
        }
        text-decoration: underline;
        cursor: pointer;
        font-size: 15px;
        color: #111111;
        display: inline-block;
      }
    }

    .alphabet-wrap {

      .single-letter {
        &:last-child {
          margin-bottom: 95px;
        }
        padding-left: 0;
        margin-bottom: 30px;

        .column {
          display: flex;
          flex-wrap: wrap;
          font-size: 16px;
          line-height: 36px;
          color: #111111;

          a {
            cursor: pointer;
          }

          h2 {
            font-family: 'UniversLTCYRRoman', 'sans-serif';
            margin: 0;
            cursor: default;
          }

        }

        .un-line {
          height: 1px;
          width: calc(100% - 30px);
          margin-left: 15px;
          background-color: $grid-grey;
          margin-top: 12px;
        }

      }

    }

    .to-top {
      display: none;
      justify-content: center;
      align-items: center;
      width: 85px;
      height: 85px;
      background-color: $grid-grey;
      border-radius: 50%;
      margin: 0 auto 0px;
      position: fixed;
      left: calc(50% - 42.5px);
      bottom: 190px;
    }

  }

}

@media screen and (max-width: 1199px) {
  .top-slider {
    .slide-wrap {

      &.loaded-carousel {
        .brand-column {

          &.slick-current {
            margin-left: 0px;
            transform: translate(-1px, 0);
          }

        }
      }
    }
  }

  .main-part {

    .fil-sort-side {

      .sort-side, .fil-side {

        h2 {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .top-slider {
    .slide-wrap {

      &.loaded-carousel {
        .brand-column {

          &.slick-current {
            transform: translate(-1px, 0);
          }

        }
      }

      .slick-next {
        right: -20px;
      }

      .slick-prev {
        left: -20px;
      }
    }
  }
  .main-part {
    .main-content {
      padding-left: 0;
    }

    .fil-sort-side {
      padding-right: 0;
    }
  }

}

@media (max-width: 767px) {
  .top-slider {
    margin: -3px -15px 17px;
    transform: translate(-2px, 0);
    overflow: hidden;

    .slide-wrap {
      margin: 0 -17% 0 0;
      border-top-color: transparent;

      &.loaded-carousel {
        .brand-column {

          &.slick-current {
            transform: translate(0px, 0);
          }

        }
      }

      .slick-next, .slick-prev {
        display: none !important;
      }
    }
  }

  .main-part {

    .fil-sort-side {

      .sort-side, .fil-side {

        margin-bottom: 17px;

        h2 {

          &:after {
            display: block;
          }

          font-size: 20px;
        }

        .sort-list {
          display: none;
          li {
            font-size: 22px;
          }

        }
      }
    }

    .main-content {

      .az-line {

        justify-content: center;
        flex-wrap: wrap;

        a {
          font-size: 24px;
          padding: 0px 14px;
        }
      }

      .single-letter {
        &:last-child {
          .un-line {
            display: none;
          }
        }

        .column {
          a {
            font-size: 22px;
          }
          h2 {
            font-size: 48px;
          }
        }

      }
      .fixed-vis {
        display: flex;
      }
    }
  }

}

