$text-block-color: #363636;

.video-overlay {
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
  cursor: pointer;
  font-size: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.video-active-mob{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 20;
  display: none;
  height: 100%;
  cursor: pointer;
}

.carousel-control.left,
.carousel-control.right
{
  background: transparent;
}
.carousel-navigation-left{
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translate(0%,-50%);
  font-size: 50px;
  left: -50px;
  color: white;
}
.carousel-navigation-right{
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translate(0%,-50%);
  font-size: 50px;
  right: -50px;
  color: white;
}





.video-block{
  max-width: 100%;

}
.info-section{
  margin-top: 30px;
  .small-block{
    width: 100%;
    height: 286px;

    >a {
      &:hover {
        text-decoration: none;
      }
    }
  }
  .big-block{
    width: 100%;
    height: 519px;

    >a {
      &:hover {
        text-decoration: none;
      }
    }
  }
  .title-block{
    background: $blue-grey;
    margin-bottom: 30px;
    color: white;
    padding: 56px 100px 0 14px;
    line-height: 21px;
    width: 100%;
    height: 213px;
    .title{
      text-transform: uppercase;
      font-size: 20px;
      margin-bottom: 14px;
    }
    .sub-title{
      font-size: 14px;
    }
  }
  .text-block{
    padding-top: 10px;
    padding-bottom: 17px;
    p{
      margin: 0;
      line-height: 18px;
    }
  }
}

@media (max-width: 1199px) {
  .info-section{
    .small-block {
      height: 236px;
    }
    .big-block {
      height: 434px;
    }
    .title-block {
      padding: 35px 45px 0 14px;
      height: 173px;
    }
  }




}
@media (max-width: 991px) and (min-width:768px) {
  .info-section{
    .title-block{
      height: 204px;
      width: 345px;
      float: left;
      margin-right: 15px;
    }
    .small-block{
      height: 267px;
      width: 345px;
      float: left;
      margin-left: 15px;
    }
    .big-block {
      width: 100%;
      height: 500px;
    }
  }




}
@media ( max-width: 768px) {
  .info-section {
    margin-top: 15px;
    .title-block {
      margin-bottom: 15px;
      color: white;
      padding: 12px 14px;
      min-height: 75px;
      height: auto;
      .title{
        margin-bottom: 7px;
      }

      .sub-title{

        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    .small-block {
      height: auto;
    }
    .big-block {
      height: auto;
    }

  }

  .video-overlay {
    background-image: url(../images/tablet-vid-pl.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    pointer-events: none;
    background-position: center;
  }

  .video-active-mob{
    display: block;
  }
}
