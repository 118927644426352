.search:not(li) {
  min-height: calc(100vh - 130px);

  .top-search {


    .search-words {
      display: block;
      font-size: 18px;
      line-height: 1;
      margin-bottom: 12px;
      margin-top: 20px;

      .big-word {
        font-size: 32px;
      }
    }
    .found {
      display: block;
      font-size: 18px;
      line-height: 1;

      .bold-found {
        font-family: "ClearSansBold", sans-serif;
        font-size: 21px;
      }
    }

    margin-bottom: 25px;
  }

  .search-results {
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    &.not-found {
      .not-found-result {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        img {
          margin-bottom: 25px;
        }

        .message {
          font-size: 18px;
          text-align: center;
        }
      }
    }

    .not-found-result {
      display: none;
    }

    .single-result {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 35px;

      .image-cover {
        height: 135px;
        width: 100%;
        margin-bottom: 15px;
        background-image: url('../images/search-test.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        text-decoration: none;
        color: black;
      }
      .result-cat {
        font-size: 14px;
        text-transform: uppercase;
        line-height: 1;
        margin-bottom: 5px;
        font-weight: normal;
        text-decoration: none;
        color: black;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }
      }
      .result-title {
        margin-bottom: 5px;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: normal;
        font-family: "ClearSansBold", sans-serif;
        line-height: 1;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }
      }
      .result-text {
        font-size: 14px;
        margin: 0;
        line-height: 16px;
      }
      .look-for {
        color: #ee435d;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .search:not(li) {
    min-height: calc(100vh - 180px);
  }
}

@media screen and (max-width: 991px) {
  .search:not(li) {
    min-height: calc(100vh - 170px);
  }
}

@media screen and (max-width: 767px) {
  .search:not(li) {
    min-height: calc(100vh - 220px);


    .top-search {


      .search-words {
        margin-top: 10px;
        margin-bottom: 5px;
      }
      .found {
      }

      margin-bottom: 20px;
    }

    .search-results {
      padding: 0;

      &.not-found {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 350px);

        .not-found-result {
          img {
            margin-bottom: 25px;
          }

          .message {
          }
        }
      }

      .single-result {
        margin-bottom: 60px;

        &:last-child {
          margin-bottom: 40px;
        }

        .image-cover {
          height: 170px;
          margin-bottom: 15px;
        }
        .result-cat {
          margin-bottom: 5px;

        }
        .result-title {
          margin-bottom: 5px;
        }
        .result-text {
        }
        .look-for {
        }
      }
    }

  }
}