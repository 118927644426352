.gastromarket-block{

  .first-subcat {
    .title {
      position: absolute;
      bottom: 0;
      left: 15px;
      background: $blue-grey;
      display: inline-block;
      width: calc(75% - 31px);
      font-size: 22px;
      line-height: 25px;
      color: white;
      padding: 8px 16px;
      font-weight: normal;

      p {
        margin: 0;
      }
    }
  }

  margin-top: 20px;
  .gastromarket-info {
    p{
      font-size: 17px;
      margin-bottom: 5px;
      line-height: 20px;

      a {
        text-decoration: underline;
      }
    }


    .gastromarket-info-logo{
      margin: 55px 0;
      img{
        margin-bottom: 18px;
      }
    }
    .small-description{
      line-height: 24px;
      font-size: 18px;
      margin-bottom: 20px;
    }
    .big-description{
      font-size: 15px;
    }
    .category-title{
      padding: 10px 17px;
      font-size: 24px;
      line-height: 24px;
      color: white;
      display: inline-block;
      width: 100%;

    }
  }

}
.gastromarket-sub-categories{
  margin-top: 20px;

  &.single-sub {
    margin-top: 150px;
  }

  &.double-sub {
    margin-bottom: 35px;

    .sub-category {
      float: right;
    }
  }

  .sub-category{
    margin-bottom: 25px;
    .sub-category-block{
      position: relative;
      .title{
        position: absolute;
        bottom: 0;
        left: 0;
        background: $blue-grey;
        display: inline-block;
        width: 100%;
        font-size: 22px;
        line-height: 25px;
        color: white;
        padding: 8px 16px;
        font-weight: normal;

        p {
          margin: 0;
        }
      }

      &.single-subcat {
        .title {
          width: 75%;
        }
      }
    }

  }
}
.all-categories-block{
  margin-top: 30px;
  margin-bottom: 15px;
  .title{
    background: $blue-grey;
    height: 159px;
    padding: 28px 20px;
    color: white;
    p{
      margin: 0;
      font-size: 22px;
    }
    img{
      margin-top: -15px;
    }
  }
  .category-list{
    margin-bottom: 20px;
    a{
      height: 40px;
      padding: 0 20px;
      padding-right: 0;
      color: white;
      line-height: 40px;
      font-size: 20px;
      display: inline-block;
      width: 100%;

    }
  }
}

@media (max-width: 1199px) {
  .gastromarket-block{
    .gastromarket-info{
      .gastromarket-info-logo {
        margin:5px 0;
      }
      .small-description {
        line-height: 20px;
        font-size: 17px;
        margin-bottom: 15px;
      }
      .big-description {
        font-size: 14px;
      }
    }
  }
  .all-categories-block{
    .category-list{
      a {
        font-size: 16px;
      }
    }
  }
}
@media (max-width: 991px) {
  .gastromarket-block {
    margin-top: 25px;
    .gastromarket-info{
      .gastromarket-info-logo {
        margin: 20px 0;
        img {
          margin-bottom: 20px;
        }
      }
      .category-title {
        padding: 12px 15px;
        font-size: 30px;
        line-height: 30px;
        height: inherit;
      }
      .small-description {
        line-height: 30px;
        font-size: 24px;
        margin-bottom: 15px;
        margin-top: 35px;
      }
      .big-description {
        font-size: 18px;
        line-height: 21px;
      }
    }

  }
  .gastromarket-sub-categories{
    .sub-category{
      .sub-category-block{
        .title {
          width: 100%;
          font-size: 25px;
          padding: 15px 0;
          padding-left: 16px;
        }
      }
    }
  }
  .all-categories-block{
    .title {
      margin-bottom: 20px;
    }
  }


}
@media (max-width: 767px) {
  .gastromarket-block {
    .gastromarket-info{
      .gastromarket-info-logo {
        margin: 20px 0;
        img {
          margin-bottom: 20px;
        }
      }
    }
  }
  .gastromarket-sub-categories{
    .sub-category{
      .sub-category-block{
        .title {
          font-size: 20px;
          height: auto;
          width: 100%;
          padding: 10px 16px;
          font-size: 18px;
          line-height: 20px;
          position: relative;
        }
      }
    }
  }

  .gastromarket-block .first-subcat .title {
    padding: 10px 16px;
    font-size: 18px;
    width: 100%;
    left: inherit;
    top: inherit;
    line-height: 20px;
    position: relative;
  }
}

.new_gastro {
  text-align: center;
  margin: 40px 0px;
  h4 {
    font-family: "ClearSansBold", sans-serif;
    font-weight: 600;
  }
  p {
    font-family: 'HelveticaNeueCyrRoman';
  }
}