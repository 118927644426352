.boutique-top-block{
  margin-top: 47px;
  .boutique-info{
    border-bottom: 1px solid #d6d6d6;
    min-height: 362px;

    .boutique-link {
      font-size: 17px;
      border-bottom: 1px solid black;
      margin-bottom: 30px;
      display: inline-block;
      line-height: 18px;

      &:hover {
        color: #ef415e;
        border-color: #ef415e;
        text-decoration: none;
      }
    }

    p{
      font-size: 17px;
      margin-bottom: 5px;
      line-height: 17px;
    }
    .look-on-map{
      font-size: 15px;
      text-decoration: underline;
    }
    .boutique-info-logo{
      /*height: 165px;*/
      position: relative;
      margin: 10px 0;
      img{
        /*left: 0;
        top: 50%;
        position: absolute;
        transform: translate(0, -50%);
        max-height: 165px;*/
        max-height: 90px;
      }
    }
    .small-descriptions{
      margin-bottom: 10px;
      line-height: 20px;
    }

    .socials-btns{
      margin-bottom: 5px;
      a{
        font-size: 32px;
        line-height: 30px;
        color: #bfbfbf;
        &:hover{
          text-decoration: none;
          color: #939393;
          cursor: pointer;
        }
      }
      .icon-socials-web,
      .icon-socials-vk{
        font-size: 30px;
      }
    }
  }
}
.boutique-second-block{
  margin-top: 20px;
  .boutique-big-description{
    p{
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 20px;
    }
    p:last-child{
      margin: 0;
    }
  }
}
.boutique-third-block{
  margin-top: 20px;
  margin-bottom: 30px;
}
.brands-block{
  margin-top: 20px;
  margin-bottom: 22px;
  .title{
    height: 80px;
    line-height: 80px;
    background: $blue-grey;
    padding: 0 15px;
    color: white;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .brands-logo{
    margin-bottom: 20px;
  }
}

@media (max-width: 1199px) {
  .boutique-top-block{
    .boutique-info  {
      min-height: 296px;

      .look-on-map{
        font-size: 12px;
      }
      .boutique-info-logo{
        height: 68px;
        img {
          max-height: 68px;
        }
      }
      p {
        font-size: 15px;
        margin-bottom: 5px;
        line-height: 15px;
      }
    }
  }
  .boutique-second-block{
    .boutique-big-description  {
      p{
        font-size: 13px;
        line-height: 17px;
        margin-bottom: 10px;
      }

    }
  }
  .brands-block{
    .title{
      height: 64px;
      line-height: 64px;
    }
  }
}
@media (max-width: 991px){
  .boutique-top-block .boutique-info .boutique-info-logo img {
    max-height: 130px;
  }

  .boutique-top-block .boutique-info .boutique-info-logo {
    height: inherit;
  }

  .boutique-top-block {
    margin-top: 15px;
  }
  .boutique-top-block{

    .boutique-info {
      border: none;
      min-height: inherit;
    }
  }
  .boutique-second-block{
    .boutique-big-description{
      p {
        letter-spacing: 0;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 20px;
      }
      p:last-child {
        margin-bottom: 20px;
      }
    }
  }
}
@media (max-width: 768px){
  .boutique-top-block{
    margin-top: 47px;
      .boutique-info {
        border: none;
      }
    }
    .boutique-second-block{
      .boutique-big-description{
        p {
          letter-spacing: 0;
          font-size: 15px;
          line-height: 20px;
          margin-bottom: 20px;
        }
        p:last-child {
          margin-bottom: 20px;
        }
      }
  }
}
