$map-blue : #66c5d0;
$map-hv-blue : #3fb0c2;
$map-pink-red : #e73554;
$map-grey : #86929d;
$map-dark-bl : #31b1c0;
$side-pink: #ee435d;
$side-grey: #a3a3a3;

body {
  -webkit-overflow-scrolling: touch;
}

.map {
  .main-part {
    margin: 0 -15px;

    .sidecar {
      .boutique-list {
        >h2 {
          height: 38px;
          display: block;
          padding-left: 15px;
          color: white;
          font-family: "ClearSansBold", sans-serif;
          font-weight: normal;
          line-height: 38px;
          font-size: 18px;
          text-transform: uppercase;
          background-color: #d7d7d7;
          margin: 0;
          position: relative;

          .close-bout {
            right: 10px;
            top: 5px;
          }

          .open-bout {
            right: 15px;
            top: 15px;
          }

          .close-bout, .open-bout {
            display: none;
            position: absolute;
          }
        }

        .single-list {
          h3 {
            display: block;
            height: 30px;
            text-transform: uppercase;
            font-size: 16px;
            padding-left: 15px;
            color: white;
            background-color: #707070;
            margin: 0;
            line-height: 30px;
            border-bottom: 1px solid #d7d7d7;
            cursor: pointer;
            position: relative;

            &:after {
              content: '';
              position: absolute;
              top: 11px;
              right: 10px;
              width: 0;
              height: 0;
              border-top: 4px solid transparent;
              border-bottom: 4px solid transparent;
              border-left: 7px solid #ffffff;
            }
          }

          &.active {
            h3 {

              &:after {
                content: '';
                position: absolute;
                top: 11px;
                right: 10px;
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 7px solid #ee435d;
              }
            }

            .content {
              display: flex;
            }
          }

          &:last-child {
            h3 {
              border: 0;
            }
          }

          .content {
            display: none;
            flex-direction: column;
            //max-height: 150px;
            height: 100%;


            span {
              display: block;
              height: 20px;
              font-size: 13px;
              line-height: 20px;
              padding-left: 15px;
              cursor: pointer;
              text-transform: uppercase;

              &.active {
                background-color: $side-pink;
                color: white;
              }

              &:hover {
                background-color: $side-pink;
                color: white;
              }
            }
          }
        }
      }

      .floors {

        margin-bottom: 20px;

        h2 {
          color: #7f7d7d;
          font-size: 18px;
          text-transform: uppercase;
          margin-top: 20px;
          margin-bottom: 5px;
          padding-left: 15px;
        }

        .content {
          height: 68px;
          display: flex;
          justify-content: flex-start;

          span {
            display: block;
            height: 100%;
            width: 33.33333%;
            border: 1px solid $side-grey;
            border-top: 4px solid $side-grey;
            font-size: 44.6px;
            color: #a3a3a3;
            text-align: center;
            line-height: 60px;
            cursor: pointer;
            position: relative;

            &.active {
              border-color: $side-pink;
              color: $side-pink;
              z-index: 1;

              &:after {
                content: '';
                position: absolute;
                top: -1px;
                left: calc(50% - 4px);
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 7px solid $side-pink;
              }
            }

            &:nth-child(2) {
              margin: 0 -1px;
            }
          }
        }
      }
    }

    .map-column {

      .swipe-cover {

        &.on-slide {
          height: calc(100vh - 150px);
          overflow: hidden;
          .swipe-wrapper {
            height: 300%;
            transform: translate(0px, 0%);
            transition: 1500ms ease-in-out;

            .floor-cover {
              height: calc(100vh - 150px);

              .single-floor {
                max-height: 85%;
              }

            }
          }
        }
        .swipe-wrapper {
          .floor-cover {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .single-floor {
              max-height: 85%;
            }

            .floor-name {
              display: none;
            }
          }
        }
      }



      .single-floor {
        max-width: 90%;
        max-height: 70%;
        margin: 0 auto;

        .not-rented {
          fill: $map-grey;
        }
        .not-rented, .rent, .spec {
          fill-rule: evenodd;
        }

        .spec {
          fill: #dedddd;
        }

        .rent {
          &.hovered {
            fill: $map-hv-blue;
          }
          &.active {
            fill: $map-pink-red;
          }
          fill: $map-blue;

          cursor: pointer;
        }

        .map-text {
          &.active {
            fill: white;
          }

          cursor: pointer;
          * {
            cursor: pointer;
          }

          &.spec-text {
            fill: $map-blue;
            cursor: default;

            &.active {
              fill: $map-blue;
            }
          }

          font-size: 8.5px;
          font-family: ClearSansRegular;
        }

        .small-text {
          &.active {
            fill: white;
          }

          cursor: pointer;
          * {
            cursor: pointer;
          }
          font-size:4.58px;
          font-family: ClearSansRegular;
        }

        .path-legend, .map-legend, .top-lay {
          &.hovered {
            cursor: pointer;
          }
          &.active {
            fill: white;
            cursor: pointer;
          }
          fill: $map-dark-bl;
        }

        .top-lay {
          fill: #026e7a;
        }

        .parking {
          font-size: 18px;
          fill: $map-dark-bl;
          font-family: ClearSansRegular;
        }

        .free {
          fill: #dedddd;
        }

      }
      .map-popup {
        &.vis-popup {
          display: block;
        }

        width: 300px;
        height: initial;
        position: fixed;
        bottom: 110px;
        right: 100px;
        border: 1px solid black;
        background-color: white;
        padding: 40px 20px;
        z-index: 5;
        display: none;

        .close-popup {
          width: 30px;
          height: 30px;
          position: absolute;
          top: 10px;
          right: 10px;

          span {
            &:nth-child(1) {
              background-color: #f47a8e;
              transform: rotate(45deg) translate(10px, 10px);
            }
            &:nth-child(2) {
              background-color: #6ecad4;
              transform: rotate(-45deg) translate(-8.5px, 9px);
            }
            width: 100%;
            height: 2px;
            display: block;
            border-radius: 3px;
            cursor: pointer;
          }

          cursor: pointer;
        }

        .content {
          &.vis-content {
            display: flex;
          }
          display: none;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 100%;
          width: 100%;

          .text-content {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;

            >a {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              text-decoration: none;

              img {
                max-width: 100%;
                max-height: 140px;
              }
            }

            >div {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }

            span {
              font-size: 18px;
              font-family: UniversLTCYRRoman, sans-serif;
              line-height: 1.4;
              display: block;
            }

            h3 {
              text-decoration: underline;
              padding-bottom: 0px;
              margin-bottom: 40px;
              font-size: 24px;
              font-family: Calibri, sans-serif;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .map {
    .main-part {
      .map-column {
        .swipe-cover {

          &.on-slide {
            height: calc(100vh - 200px);
            .swipe-wrapper {
              .floor-cover {
                height: calc(100vh - 200px);

                .single-floor {
                  max-height: 85%;
                }

              }
            }
          }
        }
      }
    }
  }

}

@media screen and (max-width: 991px) {
  .nav {
    .search {
      .search-block.not-enough {
        .n-en-message {
          top: -40px;
          width: calc(90% - 20px);
          left: 0;
        }
      }
    }
  }

  .map .main-part .sidecar .boutique-list .single-list .content {
    max-height: inherit;
  }


  .map {
    margin-top: 0;

    .main-part {

      .sidecar {
        margin-bottom: 0px;
        display: flex;
        flex-direction: column-reverse;

        .floors {
          margin-bottom: 0;
        }

        .boutique-list {


          &.vis-boutique {
            h2 {
              .close-bout {
                display: block;
              }

              .open-bout {
                display: none;
              }
            }

            .single-list {
              display: block;
            }
          }



          h2 {
            height: 50px;
            line-height: 50px;
            font-size: 25.7px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 15px;

            .open-bout {
              display: block;
              width: 0;
              height: 0;
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-left: 15px solid $map-blue;
            }

            .close-bout {
              width: 40px;
              height: 40px;

              span {
                width: 40px;
                height: 3px;
                display: block;

                &:first-child {
                  transform: rotate(-45deg) translate(-13px, 13px);
                  background-color: #6ecad4;
                  position: relative;
                  z-index: 1;
                }

                &:last-child {
                  transform: rotate(45deg) translate(11px, 11px);
                  background-color: #f47a8e;
                }
              }
            }
          }

          .single-list {
            display: none;

            h3 {
              height: 45px;
              font-size: 22.8px;
              line-height: 45px;

              &:after {
                content: '';
                position: absolute;
                top: 17px;
                right: 15px;
                width: 0;
                height: 0;
                border-top: 6px solid transparent;
                border-bottom: 6px solid transparent;
                border-left: 11px solid #ffffff;
              }
            }

            &.active {
              h3 {
                &:after {
                  content: '';
                  position: absolute;
                  top: 18px;
                  right: 15px;
                  width: 0;
                  height: 0;
                  border-left: 6px solid transparent;
                  border-right: 6px solid transparent;
                  border-top: 11px solid #ee435d;
                }
              }
            }

            .content {
              span {
                height: 28px;
                line-height: 28px;
                font-size: 18.5px;
              }
            }
          }
        }
      }

      .map-column {
        .floor-cover {
          height: inherit;
          margin-bottom: 60px;

          >.floor-name {
            color: #86929e;
            font-size: 24px;
            margin: 0px 0 20px 0;
            display: block;
            width:100%;
            text-align: right;
            line-height: 1;
          }
        }


        .map-popup {
          right: 0px !important;
          top: 0px;
          bottom: 0px;
          left: 0px;
          width: 100%;
          border: 0px;

          .content {
            .text-content {
              display: flex;
              flex-direction: column;
              align-items: center;
              height: calc(40% - 30px);
              justify-content: space-between;
              margin-bottom: 30px;

              > div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              }

              span {
                font-size: 21.8px;
              }

              h3 {
                font-size: 29px;
                margin-bottom: 50px;
              }
            }
            .map-content {
              height: 40%;
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;

              .floor-name {
                color: #86929e;
                font-size: 24px;
                margin: 0px 0 20px 0;
                display: block;
                width:100%;
                text-align: right;
                line-height: 1;
              }

              svg {
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }



  .map .main-part .sidecar .floors h2 {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 767px) {
  .map .main-part .map-column .map-popup .content .text-content {
    /*height: calc(45% - 30px);
    min-height: 200px;*/
    height: 50%;
    min-height: inherit;
    display: block;
  }

  .map .main-part .map-column .map-popup .content .text-content > a img {
    max-height: 80px;
  }

  .map .main-part .map-column .single-floor {
    max-height: 90%;
  }

  .map .main-part .map-column .map-popup .content {
    justify-content: space-around;
  }

  .map .main-part .map-column .map-popup .content .text-content > div {
    height: 100px;
    display: block;
    text-align: center;
    margin-top: 10px;
  }

  .map .main-part .map-column .swipe-cover.on-slide {
    height: calc(100vh - 240px);
  }

  .map .main-part .map-column .swipe-cover.on-slide .swipe-wrapper .floor-cover {
    margin-bottom: 0;
    height: calc(100vh - 240px);
  }

  .map .main-part .map-column .swipe-cover.on-slide .swipe-wrapper .floor-cover .single-floor {
    max-height: 70%;
    margin-bottom: 30%;
  }

}

@media screen and (max-width: 767px) and (orientation: portrait) {
  .map .main-part .map-column .map-popup .content .text-content {
    max-height: 50%;
    min-height: inherit;
    display: block;
    height: auto;
  }

  .map .main-part .map-column .map-popup .content.vis-content {
    display: flex;
    justify-content: center;
  }

  .map .main-part .map-column .map-popup .content .text-content h3 {
    text-align: center;
  }

  .map .main-part .map-column .map-popup .content .map-content {
    height: 50%;
  }
}


@media screen and (max-width: 991px) and (orientation: landscape) {
  .map {
    .main-part {

      .map-column {

        .map-popup {
          right: 0px;
          top: 0px;
          bottom: 0px;
          left: 0px;
          width: 100%;
          border: 0px;

          .content {
            flex-direction: row;

            .text-content {
              height: 100%;
              width: 50%;
              margin-bottom: 0px;

              img {
                height: 75px;
              }

              span {
                font-size: 18px;
              }

              h3 {
                font-size: 24px;
                margin-bottom: 0px;
                margin-top: 0;
              }
            }
            .map-content {
              height: 100%;
              width: 50%;
              display: flex;
              flex-direction: column;
              align-items: flex-end;

              svg {
                margin-bottom: 0px;
                height: 80%;
              }
            }
          }
        }
      }
    }
  }
}



@media only screen
and (min-device-width: 992px)
and (max-device-width: 1199px)
and (orientation: landscape) {
  body, html {
    overflow: hidden;
  }
}