$events-color: #5e6e7d;
$colibri-red: #ef405d;
$colibri-turquoise: #31b3c1;
$colibri-lite-turquoise: #b9e4ea;
.events{
  margin-top: 17px;
  font-family: 'UniversLTCYRRoman', 'sans-serif';
}
.anons{
  margin-bottom: 15px;
}
.archive{
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
}

.event{
  margin-bottom: 20px;
  &__link {
    &:hover {
      text-decoration: none;
    }
  }

  &__box{
    position: relative;
    //height: 360px;
    color: white;
    &__top{
      position: relative;
      background: $events-color;
      padding: 112px 13px 0 13px;
      &__title{
        font-size: 18px;
        word-spacing: 4px;
        margin-bottom: 18px;
        &_big{
          font-size: 36px;
          margin-left: 5px;
          position: relative;
        }
      }
      &__text{
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.4px;
        color: #cccccc;
      }
      &__bird{
        position: absolute;
        top: -39px;
        right: -41px;
      }
    }
    &__date{
      position: absolute;
      top: 0;
      padding-top: 15px;
      text-align: center;
      font-family: 'ClearSansRegular', 'sans-serif';
      width:100px;
      height: 100px;
      background: $events-color;
      text-transform: uppercase;
      &__day{
        font-size: 47px;
        line-height: 47px;
        margin-bottom: 5px;
      }
      &__month{
        font-size: 15px;
        line-height: 15px;
      }
    }
    &__corner{
      &:before  {
        position: absolute;
        bottom: 0;
        right: 0;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
      }
      &_big{
        &:before  {
          border-width: 0 0 70px 70px;
        }
      }
      &_small{
        &:before  {
          border-width: 0 0 23px 23px;
        }
      }
      &_red{
        &:before  {
          border-color: transparent transparent $colibri-red  transparent;
        }
      }
      &_turquoise{
        &:before  {
          border-color: transparent transparent $colibri-turquoise transparent;
        }
      }
      &_lite-turquoise{
        &:before  {
          border-color: transparent transparent $colibri-lite-turquoise transparent;
        }
      }
      &_gray{
        &:before  {
          border-color: transparent transparent $events-color transparent;
        }
      }
    }
    &__info{
      position: relative;
      font-family: 'UniversLTCYRRoman','sans-serif';
      padding-top: 122px;
      float: left;

      >p {
        color: black;
      }

      &__title{
        color: $colibri-red;
        font-size: 23px;
        line-height: 27px;
        letter-spacing: 0.4px;
        margin-bottom: 20px;
        font-weight: bold;
      }
      &__description{
        color: black;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 23px;
      }
      &__button{
        float: right;
        font-size: 16px;
        line-height: 38px;
        width: 182px;
        text-align: center;
        text-transform: uppercase;
        color: white;
        background: $colibri-red;
        height: 41px;
        position: relative;
        &:hover{
          color: white;
        }
      }
    }
    &__archive{
      min-height: 360px;
      max-height: 706px;

      p {
        &:last-child{
          margin-bottom: 0;
        }
        color: black;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 23px;
      }

      &__date{
        font-family: "ClearSansRegular", "sans-serif";
        color: black;
        font-size: 17px;
        line-height: 12px;
        letter-spacing: -0.1px;
        margin-bottom: 31px;
        display: block;
      }
      &__title{
        color: $colibri-turquoise;
        font-size: 23px;
        line-height: 27px;
        letter-spacing: 0.4px;
        margin-bottom: 20px;
        font-weight: bold;
        display: block;
      }
      &__description{
        &:last-child{
          margin-bottom: 0;
        }
        color: black;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 23px;
      }

    }
  }
  &__description{
    margin-top: 6px;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    &_big{
      display: block;
      text-transform: uppercase;
      letter-spacing: -0.3px;
      width: calc(100% - 80px);
    }
    &__date{
      display: block;
      float: left;
      width: 70px;
      margin-top: 2px;
      font-size: 21px;
      line-height: 21px;
      padding: 4px;
      text-align: center;
      background: #e1e1e1;
      margin-right: 10px;
    }
  }
}
.archive-gallery{
  position: relative;
  cursor: pointer;

}
.last-visible-img{
  position: relative;
  width: 100%;
  display: block;
  cursor: pointer;
  &:before{
    content: '';
    width: 100%;
    height: 100%;
    background: $colibri-turquoise;
    position: absolute;
    cursor: pointer;
  }
  &__count{
    color: white;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 54px;
  }

}

.archive-gallery {
  outline: none;
  margin-bottom: 20px;
}

.slider-container {
  position: fixed;
  top: 0;
  lefT: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: none;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 235ms ease-in-out;

  &.vis {
    display: flex;
  }

  &.vis-content {
    opacity: 1;
  }

  .slider {
    position: relative;
    z-index: 2;
    max-width: 60%;

    .slick-list {
      height: 90% !important;
    }

    .archive-gallery {
      margin-bottom: 0;
      cursor: default;
      display: flex;
      align-items: center;
      justify-content: center;

      &.slick-current {
        .fancybox-nav {
          opacity: 1;
          transition: 235ms ease-in-out;
          z-index: 4;
        }

        .bot-block {
          opacity: 1;
          transition: 235ms ease-in-out;
        }

        .custom-fancybox-close {
          opacity: 1;
          transition: 235ms ease-in-out;
          z-index: 10;
        }
      }
    }

    .custom-fancybox-close {
      position: absolute;
      display: block;
      top: 10px;
      right: -40px;
      width: 39px;
      height: 34px;
      cursor: pointer;
      z-index: 4;
      opacity: 0;
      transition: 10ms ease-in-out;
    }

    .bot-block {
      display: flex;
      height: 50px;
      align-items: center;
      justify-content: space-between;
      opacity: 0;
      transition: 10ms ease-in-out;
    }

    .fancy-image-counter {
      color: white;
    }

    .slick-arrow {
      display: none !important;
    }

    .slide-content {
      position: relative;
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 1;
    }

    .sing-slide-bg {
      display: block;
      position: absolute;
      z-index: 0;
      width:100%;
      height:100%;
      top: 0;
      left: 0;
    }

    .slick-track {
      max-height: 90vh;
      display: flex;
      align-items: center;
    }

    .photo-cover {
      display: block;
      position: relative;
      max-width: 50vw;
      padding-top: 50px;
    }



    img {
      max-height: calc(90vh - 120px);
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }

  .slider-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.8);
  }
}


@media (min-width: 1200px){
  .event{
    &__box{
      &__top {
        min-height: 360px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199px){
  .event{
    &__box{
      &__top {
        min-height: 293px;
      }
    }
  }
}
@media (max-width: 1199px) {
  .event{
    &__description {
      &_big {
        width: calc(100% - 50px);
      }
    }

    &__box {
      position: relative;
      color: white;
      &__top{
        padding: 82px 13px 0 13px;
        &__title {
          font-size: 16px;
          word-spacing: 4px;
          margin-bottom: 18px;
        }
      }

      &__info {
        height: 296px;
        padding-top: 0;
        &__title{
          font-size: 20px;
          line-height: 25px;
          margin-bottom: 10px;
          font-weight: bold;
          text-align: right;
          padding-left: 110px;
        }
      }
    }
    &__description {
      font-size: 12px;
      &__date{
        width: 44px;
        margin-top: 3px;
        font-size: 14px;
        line-height: 21px;
        margin-right: 5px;
      }
    }
  }
}
@media (max-width: 991px) {
  .event{
    &__box{
      &__top {
        padding: 82px 13px 21px 13px;
      }
      &__info {
        height: 160px;
        &__title {
          padding: 0;
          text-align: left;
        }
      }
    }
  }

  .slider-container .slider {
    max-width: 80%;
  }
  .slider-container .slider .photo-cover {
    max-width: calc(80vw - 100px);
  }
  .fancybox-nav {
    width: 50px;
  }
  .slider-container .slider .slick-track {
    max-height: 75vh;
  }
}
@media (max-width: 768px) {
  .event{


    &__box{
      //height: 450px;
      &__top {
        padding: 62px 23px 21px 17px;
        //height: 216px;
        &__title {
          font-size: 22px;
          margin-bottom: 2px;
          &_big {
            font-size: 45px;
          }
        }
        &__text {
          font-size: 17px;
        }
      }
      &__corner_small{
       &:before {
         border-width: 0 0 30px 30px;
       }
      }

      &__info{
        height: 260px;
        &__title {
          font-size: 23px;
          line-height: 30px;
        }
        &__description {
          font-size: 18px;
          line-height: 24px;
        }
        &__button {
          font-size: 19px;
          width: 289px;
          height: 46px;
          line-height: 46px;
        }
      }
    }
    &__description {
      margin-top: 8px;
      font-size: 16px;

      &_big {
        width: calc(100% - 95px);
      }

      &__date {
        width: 89px;
        margin-top: 3px;
        font-size: 28px;
        line-height: 32px;
        margin-right: 5px;
      }
    }
  }

  .slider-container .slider {
    max-width: 95%;
  }
  .slider-container .slider .photo-cover {
    max-width: calc(95vw - 70px);
    padding-top: 25px;
  }

  .slider-container .slider .slick-track {
    max-height: 100vh;
  }

  .slider-container .slider img {
    margin-bottom: 0;
    max-height: calc(100vh - 80px);
  }

  .slider-container .slider .slick-list {
    height: 100% !important;
  }

  .slider .bot-block .fancy-image-counter {
    right: 0;
  }

  .slider-container .slider .bot-block {
    height: 30px;
    position: relative;
    justify-content: flex-end;
  }

  .slider-container .slider .custom-fancybox-close {
    right: -25px;
    width: 25px;
    height: 25px;
    top: 0px;
  }

  .slider-container .slider .archive-gallery .fancybox-nav {
    width: 35px;
  }

  .slider-container .slider .archive-gallery .fancybox-nav.fancybox-next {
    right: -35px;
  }

  .slider-container .slider .archive-gallery .fancybox-nav.fancybox-prev {
    left: -35px;
  }

  .slider-container .slider .archive-gallery .fancybox-nav.fancybox-next span {
    right: 0px;
  }
  .slider-container .slider .archive-gallery .fancybox-nav.fancybox-prev span {
    left: -6px;
  }

  .slider-container .slider .archive-gallery .fancybox-nav span {
    width: 35px;
    height: 35px;
    margin-top: -8px;
    font-size: 40px;
  }

}

