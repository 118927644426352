.pg-404 {
  font-family: "ClearSansRegular", "sans-serif";
  min-height: calc(100vh - 130px);

  .page-container {
    width: 390px;
    padding: 0 15px;

    .bl-404 {
      background-color: #5d6f7d;
      height: 360px;
      margin-top: 20px;

      .title-cover {
        position: relative;
        display: inline-block;
        margin-top: 60px;
        margin-bottom: 25px;

        h1 {
          font-size: 100px;
          color: white;
          position: relative;
          z-index: 2;
          line-height: 85px;
          margin: 0;
        }

        &:after {
          content: '';
          display: block;
          z-index: 1;
          width: 55px;
          height: 72px;
          top: -25px;
          right: -10px;
          position: absolute;
          background-image: url('../images/colib-bird.png');
        }
      }


      .no-page {
        font-family: "ClearSansBold", sans-serif;
        font-size: 17px;
        color: white;
        line-height: 1;
        margin-bottom: 15px;
        display: block;
      }

      .to-the-main {
        color: white;
        text-decoration: none;
        font-size: 17px;
        line-height: 1;
        display: inline-block;
        cursor: pointer;
        border-bottom: 1px solid white;
        padding-bottom: 2px;

        &:hover {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }


}

@media screen and (max-width: 1199px) {
  .pg-404 {
    min-height: calc(100vh - 180px);
  }
}

@media screen and (max-width: 991px) {
  .pg-404 {
    min-height: calc(100vh - 170px);
  }
}

@media screen and (max-width: 767px) {
  .pg-404 {
    min-height: calc(100vh - 220px);
    background-color: #5d6f7d;
    display: flex;
    align-items: center;
    justify-content: center;

    .bl-404 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

  }
}