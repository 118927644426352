.about{
  margin-top: 10px;
  .text-block{
    .title{
      font-family: UniversLTCYRBoldCond;
      text-transform: uppercase;
      color: #f04c67;
      margin-top: 26px;
      letter-spacing: -0.1px;
      font-size: 24px;
      margin-bottom: 14px;
    }
    .text{
      margin: 20px 0;
      letter-spacing: -0.3px;
      font-size: 14px;
      line-height: 19px;
      font-family: UniversLTCYRRoman;


      .start{
        font-family: UniversLTCYRBoldCond;
        text-transform: uppercase;
        letter-spacing: 0.6px;
        font-size: 15px;
      }
    }
  }
  .big-img{
    float: right;


  }
  .small-img{
    margin-top: 20px;
  }
}
.about-info{
  font-family: UniversLTCYRRoman;
  margin-top: 15px;
  margin-bottom: 92px;
  .top-block{
    height: 143px;
    background: #5f6e7d;
    .icon-block{
      padding: 5px 0 5px 12px;
      text-align: center;
      span{
        color: white;
        font-size: 57px;
      }
      .icon-parking_2{
        font-size: 70px;
      }
    }
    .text-block{
      padding: 25px 25px 0 5px;
      color: white;
      .title{
        text-transform: uppercase;
        margin: 0;
        font-size: 20px;
      }
      .text{
        margin-top: 6px;
        font-family: UniversLTCYRLight;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.1px;
      }
    }
  }
  .info-block{
    font-family: UniversLTCYRRoman;
    padding-top: 27px;
    padding-left: 22px;
    margin-top: 18px;
    background: #e1e1e1;
    font-size: 15px;
    min-height: 210px;
    .title{
      font-weight: bold;
      text-transform: uppercase;
      margin: 0 0 5px;
    }
    ul.text{
      padding-left: 15px;
      &.indented{
        li{
          margin-top: 3px;
          &:first-child{
            margin-top: 0;
          }
        }
      }

      span{
        display: inline-block;
        float: right;
        font-size: 15px;

        &.days {
          width: 60px;
        }
      }
      .time{
        margin: 0 20px 0 5px;
        min-width: 80px;
        text-align: right;
      }

    }
    .text{
      line-height: 22px;
      letter-spacing: -0.1px;
      margin-bottom: 0;
      &.gray{
        line-height: 1.2;
        margin-top: 5px;
        font-size: 14px;
        color: #707070;
      }
    }
    a.map{
      margin-left: 15px;
      color: #707070;
      text-decoration: underline;
      cursor: pointer;
    }
  }

}

.about {
  .big-img {
    .vid-overlay {
      background-image: url(../images/mob-vid-pl.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      pointer-events: none;
      width: calc(100% - 30px);
      height: 100%;
      display: none;
      z-index: 1;
      cursor: pointer;
      font-size: 0;
      position: absolute;
      top: 0;
      left: 15px;
    }

    .vid-active-mob{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 20;
      display: none;
      cursor: pointer;
      height: 100%;
    }
  }
}

@media (max-width: 1199px) and (min-width: 992px){
  .about .small-img {
    margin-top: 20px;
    height: 325px;
    overflow: hidden;
    position: relative;
    img{
      width: 617px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .about-info .info-block ul.text span {
    font-size: 12px;
  }

  .about-info .info-block ul.text span.days {
    width: 53px;
  }

}
@media (max-width: 1199px) {
  .about-info{

    .top-block {
      height: 170px;
    }

    .info-block{
      font-size: 13px;
      ul.text{
        .time {
          margin: 0 5px;
        }
      }
      .text{
        &.gray{
          font-size: 16px;
        }
      }
    }
  }

  .about {
    .big-img {

    }
  }
}
@media (max-width: 991px) {

  .about-info{
    .top-block {
      height: 140px;
    }

    .info-block {
      font-size: 18px;
      min-height: 1px;
      padding-bottom: 25px;
      margin: 0 0 20px;
      padding-right: 30px;

      .title {
        text-align: left;
      }

      ul.text{
        .time {
          margin: 0 100px;
          min-width: 97px;
        }
      }
    }
    .top-block{
      .text-block{
        .title {
          font-size: 25px;
        }
        .text {
          font-size: 16px;
        }
      }
      .icon-block{
        span {
          font-size: 70px;
        }
        .icon-parking_2 {
          font-size: 80px;
        }
      }
    }

  }

  .about-info .info-block ul.text span {
    font-size: 18px;
  }

  .about-info .info-block ul.text span.days {
    width: 82px;
  }
}
@media (max-width: 767px) {
  .about-info{
    .top-block {
      display: flex;
      align-items: center;
      min-height: 160px;
      height: auto;
      .icon-block {
        padding: 5px 0 5px 0;
      }
      .text-block{
        padding: 0px 25px 0 5px;
        .text {
          font-size: 17px;
          margin: 0;
          line-height: 23px;
        }
      }
    }
    .info-block {
      padding-top: 20px;
      ul.text{
        .time {
          margin: 0 18px;
        }
      }
    }
  }

  .about {
    .big-img {
      .vid-overlay {
        pointer-events: auto;
        display: block;
      }

      .vid-active-mob{
        display: block;
      }
    }
  }

}
