
// Fonts

// Variables
@import "variables";

@import "fonts";

@import "iconfont_variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Slick Slider
@import "node_modules/slick-carousel/slick/slick.scss";

// Plyr
@import "node_modules/plyr/src/scss/plyr";

@import "main";

@import "menu";
@import "footer";

@import "index-page";
@import "boutique";
@import "gastromarket";
@import "about";
@import "events";
@import "brands";
@import "contacts";
@import "map";
@import "search";
@import "pg404";

.pre-loader {
  border: 5px solid #f3f3f3;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border-top: 5px solid #555;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 50%;
  left: 50%;
  display: none;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media screen and (min-width: 768px) {

  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    height: 52px;
  }

  .page-wrapper {
    > .wrapper {
      padding-top: 111px;
      padding-bottom: 52px;
    }
  }

  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    background-color: white;

    .nav-container {
      padding: 0 15px 0 15px;
      position: relative;
      z-index: 5;
    }

    > .container {
      .navbar-brand {
        margin-left: 5px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .page-wrapper {
    > .wrapper {
      padding-top: 62px;
      padding-bottom: 52px;
    }
  }

  .navbar {
    .nav-container {
      padding: 0 15px 0 30px;
    }

    > .container {
      .navbar-brand {
        margin-left: -15px;
      }
    }
  }
}